import React from 'react';
import { Layout, SEO } from 'components/common';
import { Intro, Resume } from 'components/landing';

export default () => (
  <Layout>
    <Intro />
    <SEO title="Benjamin Esh: Full Stack Engineer Resume" location="/resume" />
    <Resume />
  </Layout>
);
